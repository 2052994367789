import archiveIcon from "@assets/icons/archive.svg";
import { ReactComponent as ChangeLogIcon } from "@assets/icons/changeLog/change_log.svg";
import activeIcon from "@assets/icons/check-verified-02.svg";
import { formatDistanceToNow, parseISO } from "date-fns";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUpdateWorkflowStatus } from "src/apis";
import ProtectedComponent from "src/components/ProtectedComponent";
import {
  STATUS_CONFIG_WITH_ARCHIVE,
  WORKFLOW_FILE_EXTENSION,
} from "src/config";
import { PERMISSIONS_TYPE } from "src/constants/permissionsConstant";
// import chevronDown from "@assets/icons/chevron-down.svg";
import { ReactComponent as WorkflowIcon } from "@assets/icons/dataflow-01.svg";
import draftIcon from "@assets/icons/edit-05.svg";
import reviewIcon from "@assets/icons/file-search-02.svg";
import { ReactComponent as InfoIcon } from "@assets/icons/info_circle.svg";
import axios from "@axios";
import Button from "@components/Button";
import ConfirmationModal from "@components/ConfirmationModal";
import Menu from "@components/Menu";
import StatusNotifyModal from "@components/StatusNotifyModal";
import { Tooltip } from "@finbox-in/finblocks";
import { InputVariable } from "@screens/create-policy/Sources/types";
import { useWorkflowContext } from "@screens/workflow/WorkflowContext";
import {
  BE_BRANCH_NODE_TYPE,
  BE_DECISION_TABLE_NODE_TYPE,
  BE_END_NODE_TYPE,
  BE_MODEL_NODE_TYPE,
  BE_MODEL_SET_NODE_TYPE,
  BE_POLICY_NODE_TYPE,
  BE_RULE_SET_NODE_TYPE,
  BE_SOURCE_NODE_TYPE,
  BE_START_NODE_TYPE,
  BE_SWITCH_NODE_TYPE,
  BE_WORKFLOW_NODE_TYPE,
} from "@screens/workflow/config";
import useMonacoContext from "@screens/workflow/studio/MonacoContext";
import { getBranchConditionsQuery } from "@screens/workflow/studio/components/Branch/queries";
import { getModelExpressionQuery } from "@screens/workflow/studio/components/Model/queries";
import { getModelSetItemsQuery } from "@screens/workflow/studio/components/ModelSet/queries";
import { getOutcomeConfig } from "@screens/workflow/studio/components/Outcome/queries";
import { getRuleSetConditionsQuery } from "@screens/workflow/studio/components/RuleSet/queries";
import { getCurrentSourcesQuery } from "@screens/workflow/studio/components/Source/queries";
import {
  Databasedswitchstate,
  Operationstate,
  Transitiondatacondition,
} from "@screens/workflow/types";
import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import { FinBoxResponse, PermissionTypes, PolicyStatus } from "@types";
import {
  generateAndDownloadFile,
  generateUUID,
  getNetworkErrorText,
  notify,
  titleCase,
} from "@utils/utils";
import { analyticsInstance } from "src/config/event-analytics";
import { PolicyStudioActions } from "src/constants/EventAnalytics";
import { ExportNode } from "./WorkflowStudioTopbar.types";

const STATUS_ICONS = {
  draft: <img alt="" src={draftIcon} />,
  inreview: <img alt="" src={reviewIcon} />,
  active: <img alt="" src={activeIcon} />,
  archive: <img alt="" src={archiveIcon} />,
  readytodeploy: <img alt="" src={activeIcon} />,
};

export function WorkflowStudioTopbar({
  setIsChangeLog,
  setIsWorkflowInfo,
}: {
  setIsWorkflowInfo: (isOpen: boolean) => void;
  setIsChangeLog: (isOpen: boolean) => void;
}) {
  const { workflow, isFetchingWf, isWorkflowEditable } = useWorkflowContext();

  const [confirmationType, setConfirmationType] = useState("");
  const [isDownloadProgress, setIsDownloadProgress] = useState(false);

  const [newStatus, setNewStatus] = useState<PolicyStatus | undefined>();
  const workflowStatusMutation = useUpdateWorkflowStatus(workflow?.id);
  const navigate = useNavigate();

  let updatedLast = "";
  const queryClient = useQueryClient();

  const globalIsFetching =
    useIsFetching({
      predicate: (query) => {
        return (
          typeof query.queryKey[0] !== "string" ||
          [
            "modelExpression",
            "ruleSetConditions",
            "sourceNodeSources",
          ].includes(query.queryKey[0])
        );
      },
    }) > 0;

  try {
    if (workflow?.updatedAt) {
      updatedLast = formatDistanceToNow(parseISO(workflow?.updatedAt), {
        includeSeconds: true,
      });
    }
  } catch (error) {}

  const handleDownloadWf = async () => {
    if (!workflow)
      return notify({ title: "Failed", text: "Could not find workflow" });
    setIsDownloadProgress(true);


    analyticsInstance.triggerAnalytics(
      PolicyStudioActions.TOOLBAR_DOWNLOAD,
      {
        bucket_name: workflow.policyName ?? '',
        version:workflow.policyVersion ?? '',
      }
    );

    // TODO: better solution for this.
    //       Doing this because I don't want to call the api unless it's actually going to be used.
    const inputReponse = await axios.v2.get<FinBoxResponse<InputVariable[]>>(
      `/inputs?policyType=workflow&policyID=${workflow.id}`
    );

    if (inputReponse.status > 299) {
      setIsDownloadProgress(false);
      return notify({
        title: "Failed",
        text: "Could not fetch input parameters",
      });
    }

    const inputs = inputReponse.data.data;

    const outputResponse = await axios.get<
      FinBoxResponse<
        Array<{
          name: string;
          type: "text" | "number" | "boolean";
          isMandatory: boolean;
        }>
      >
    >(`workflow/${workflow.id}/globalConfig`);

    if (outputResponse.status > 299) {
      setIsDownloadProgress(false);
      return notify({ title: "Failed", text: "Could not fetch outcomes" });
    }

    const outputs = outputResponse.data.data;

    const nodeIdNameMap: Record<string, { name: string; type: string }> = {};
    workflow?.schema.states.forEach((node, index) => {
      if (node.name) {
        if (node.name === "Start")
          nodeIdNameMap[node.name] = {
            name: "Start",
            type: BE_START_NODE_TYPE,
          };
        else if (node.name.endsWith("-switch")) {
          return;
        } else if (node.metadata?.type === BE_END_NODE_TYPE) {
          nodeIdNameMap[node.name!] = {
            name: generateUUID(),
            type: BE_END_NODE_TYPE,
          };
        } else
          nodeIdNameMap[node.name!] = {
            name: node.metadata?.name ?? index.toString(),
            type: node.metadata!.type,
          };
      }
    });

    workflow?.schema.states
      .filter((n) => n.name?.endsWith("-switch"))
      .forEach((node) => {
        if (node.name) {
          const parentNodeName =
            nodeIdNameMap[node.name.substring(0, node.name.length - 7)].name;
          nodeIdNameMap[node.name!] = {
            type: BE_SWITCH_NODE_TYPE,
            name: parentNodeName + "-switch",
          };
        }
      });

    const nodes: ExportNode[] = [];

    for (let i = 0; i < workflow.schema.states.length; ++i) {
      const node = workflow.schema.states[i];
      if (!node.name) continue;

      if (node.metadata?.type.endsWith("-switch")) {
        nodes.push({
          type: BE_SWITCH_NODE_TYPE,
          name: nodeIdNameMap[node.name!].name,
          dataConditions: (node as Databasedswitchstate).dataConditions.map(
            (c) => {
              return {
                name: c.name,
                nextState: (c as Transitiondatacondition).transition?.nextState
                  ? nodeIdNameMap[
                      (c as Transitiondatacondition).transition.nextState
                    ]
                  : { name: "", type: null },
              };
            }
          ),
        });
        continue;
      }
      switch (node.metadata?.type) {
        case BE_START_NODE_TYPE:
          nodes.push({
            type: BE_START_NODE_TYPE,
            name: node.name,
            metadata: workflow.metadata[node.name],
            nextState:
              nodeIdNameMap[(node as Operationstate).transition?.nextState!],
          });
          break;
        case BE_MODEL_SET_NODE_TYPE:
          const modelsetNodeData =
            queryClient.getQueryData(
              getModelSetItemsQuery(workflow.id, node.name).queryKey
            )?.data.data ?? [];

          nodes.push({
            type: BE_MODEL_SET_NODE_TYPE,
            name: node.metadata?.name ?? `Model_Node_${i}`,
            tag: node.metadata.tag ?? generateUUID(),
            expressions: modelsetNodeData.map((item) => ({
              name: item.name,
              id: item.id,
              seqNo: item.seqNo,
              condition: item.body,
              type: item.type,
              decisionTableRules: item.decisionTableRules,
              tag: item.tag ?? generateUUID(),
            })),
            metadata: workflow.metadata[node.name],
            nextState:
              nodeIdNameMap[(node as Operationstate).transition?.nextState!],
          });
          break;
        case BE_MODEL_NODE_TYPE:
          const modelNodeData =
            queryClient.getQueryData(
              getModelExpressionQuery(workflow.id, node.name).queryKey
            )?.data.data ?? [];

          nodes.push({
            type: node.metadata.type,
            name: node.metadata?.name ?? `Model_Node_${i}`,
            tag: node.metadata.tag ?? generateUUID(),
            expressions: modelNodeData.map((item) => ({
              name: item.name,
              id: item.id,
              seqNo: item.seqNo,
              condition: item.body,
              tag: item.tag ?? generateUUID(),
            })),
            metadata: workflow.metadata[node.name],
            nextState:
              nodeIdNameMap[(node as Operationstate).transition?.nextState!],
          });
          break;
        case BE_BRANCH_NODE_TYPE:
          const branchNodeData =
            queryClient.getQueryData(
              getBranchConditionsQuery({
                workflowId: workflow.id,
                branchId: node.name!,
              }).queryKey
            )?.data.data ?? [];

          nodes.push({
            type: BE_BRANCH_NODE_TYPE,
            name: node.metadata?.name ?? `Branch_Node_${i}`,
            tag: node.metadata.tag ?? generateUUID(),
            expressions: branchNodeData.map((item) => ({
              name: item.name,
              id: item.id,
              seqNo: item.seqNo,
              condition: item.body,
              tag: item.tag ?? generateUUID(),
            })),
            metadata: workflow.metadata[node.name],
            nextState:
              nodeIdNameMap[(node as Operationstate).transition?.nextState!],
          });
          break;
        case BE_RULE_SET_NODE_TYPE:
          const rulesetNodeData =
            queryClient.getQueryData(
              getRuleSetConditionsQuery(workflow.id, node.name!).queryKey
            )?.data.data?.rules ?? [];

          nodes.push({
            type: BE_RULE_SET_NODE_TYPE,
            name: node.metadata?.name ?? `Branch_Node_${i}`,
            tag: node.metadata.tag ?? generateUUID(),
            rules: rulesetNodeData.map((item) => ({
              name: item.description,
              id: item.rule_id,
              seqNo: item.seq,
              approveCondition: item.approve,
              cantDecideCondition: item.cant_decide ?? "",
              tag: item.tag ?? generateUUID(),
            })),
            metadata: workflow.metadata[node.name],
            nextState:
              nodeIdNameMap[(node as Operationstate).transition?.nextState!],
          });
          break;
        case BE_POLICY_NODE_TYPE:
          nodes.push({
            type: BE_POLICY_NODE_TYPE,
            name: node.metadata?.name ?? `PolicyNode`,
            metadata: workflow.metadata[node.name],
            nextState:
              nodeIdNameMap[(node as Operationstate).transition?.nextState!],
          });
          break;
        case BE_WORKFLOW_NODE_TYPE:
          nodes.push({
            type: BE_WORKFLOW_NODE_TYPE,
            name: node.metadata?.name ?? `WorkflowNode`,
            metadata: workflow.metadata[node.name],
            nextState:
              nodeIdNameMap[(node as Operationstate).transition?.nextState!],
          });
          break;
        case BE_END_NODE_TYPE:
          const endNodeConfig = queryClient.getQueryData(
            getOutcomeConfig({
              workflowId: workflow?.id!,
              nodeId: node.name!,
            }).queryKey
          )!.data.data;

          nodes.push({
            type: BE_END_NODE_TYPE,
            name: nodeIdNameMap[node.name!].name,
            endNodeName: node.metadata.name ?? `EndNode_${i}`,
            tag: node.metadata.tag ?? generateUUID(),
            from: endNodeConfig.from,
            workflowState: endNodeConfig.workflowState,
            decisionNode: endNodeConfig.decisionNode,
            metadata: workflow.metadata[node.name],
            nextState:
              nodeIdNameMap[(node as Operationstate).transition?.nextState!],
          });
          break;
        case BE_SOURCE_NODE_TYPE:
          const sourceNodeData = queryClient.getQueryData(
            getCurrentSourcesQuery(workflow?.id!, node.name!).queryKey
          )?.data.data;

          nodes.push({
            type: BE_SOURCE_NODE_TYPE,
            name: node.metadata?.name ?? `Source_Node_${i}`,
            tag: node.metadata.tag ?? generateUUID(),
            sources:
              sourceNodeData?.map((source) => ({
                name: source.name,
                id: source.id,
                seqNo: source.seq_no,
                type: source.type,
                tag: source.tag ?? generateUUID(),
              })) ?? [],
            metadata: workflow.metadata[node.name],
            nextState:
              nodeIdNameMap[(node as Operationstate).transition?.nextState!],
          });
          break;
        case BE_DECISION_TABLE_NODE_TYPE:
          // TODO
          break;
      }
    }

    const settings = {
      isNullableInputsAllowed: !!workflow.settings.isNullableInputsAllowed,
    };

    generateAndDownloadFile(
      JSON.stringify({ nodes, inputs, outputs, settings }),
      `${workflow.name}${WORKFLOW_FILE_EXTENSION}`
    );
    setIsDownloadProgress(false);
  };

  const onNotifyChangeStatus = async (
    status: PolicyStatus,
    note?: string,
    emails?: string[]
  ) => {
    if (!workflow) {
      return;
    }
    try {
      await workflowStatusMutation.mutateAsync({
        oldStatus: workflow.status as PolicyStatus,
        newStatus: status,
        workflowID: workflow.id,
        emails,
        note,
      });
      notify({
        title: "Status Changed",
        text: `Workflow status has been changed to ${status}`,
        type: "success",
      });
      setNewStatus(undefined);
    } catch (error) {
      notify({
        text: getNetworkErrorText(error),
        title: "Error",
      });
    }
  };

  const workflowStatusChange = (
    disabled:boolean,
    allowedStatus: PolicyStatus,
  ) => {
    if (disabled) return;
    if (allowedStatus === PolicyStatus.ARCHIVE) {
      setConfirmationType(`CONFIRM_ARCHIVE`);
    } else if (
      allowedStatus === PolicyStatus.READYTODEPLOY
    ) {
      setConfirmationType(
        `CONFIRM_READYTODEPLOY`
      );
    } else {
      onChangeStatus(allowedStatus);
    }
  }

  const onChangeStatus = async (status: PolicyStatus) => {
    if (!workflow) {
      return;
    }



    analyticsInstance.triggerAnalytics(
      PolicyStudioActions.TOOLBAR_STATUS,
      {
        policy_name:workflow?.policyName, 
        version:workflow?.policyVersion, 
        current_status:workflow?.status, 
        new_status:status, 
        count_blocks:Object.keys(workflow?.metadata ?? {})?.length ?? 0
      }
    );


    if (status === "draft" || status === "inreview") {
      setNewStatus(status);
      return;
    }
    onNotifyChangeStatus(status);
  };

  const monacoContext = useMonacoContext();

  return (
    <>
      <div className="z-20 text-neutral-700 font-b2 flex w-full justify-between items-center bg-white px-4 border-b border-neutral-100 h-10 gap-2">
        <div className="flex items-center gap-3">
          <Menu>
            <Menu.Button className="!shadow-none !ring-0 !py-0 !px-0 h-full rounded-md cursor-pointer group -ml-[5px]">
              <WorkflowIcon className="[&>path]:stroke-neutral-500 group-hover:[&>path]:stroke-neutral-black w-[18px] h-[18px]" />
            </Menu.Button>
            <Menu.Items className="absolute left-0 top-8 box-border rounded-md overflow-hidden bg-white shadow-lg ring-1 ring-neutral-100 z-10">
              <Menu.Item
                onClick={() => {
                  navigate(`/details/${workflow?.policyBucketId}`);
                  monacoContext.disposeAllExceptRoot();
                  monacoContext.removeFromDisposeStore("root");
                }}
                className="px-2 flex h-7 w-32 items-center gap-2 font-b2-medium hover:bg-neutral-25 hover:text-neutral-black"
              >
                Back to Dashboard
              </Menu.Item>
            </Menu.Items>
          </Menu>
          {!isWorkflowEditable && (
            <ChangeLogIcon
              className="cursor-pointer [&>path]:stroke-neutral-500 w-[18px] h-[18px] [&:hover>path]:stroke-neutral-black  "
              onClick={() => setIsChangeLog(true)}
            />
          )}
          <InfoIcon
            className="cursor-pointer [&>path]:stroke-neutral-500 w-[18px] h-[18px] [&:hover>path]:stroke-neutral-black  "
            onClick={() => setIsWorkflowInfo(true)}
          />
        </div>
        <span className="absolute left-1/2 text-neutral-black -translate-x-1/2 text-[14px] font-medium">
          <span className="text-neutral-500 uppercase mr-2">
            {workflow?.program}
          </span>
          <span className="max-w-44 w-max truncate cursor-pointer font-medium hover:bg-neutral-25 px-1 py-0.5 rounded-md">
            {workflow?.name || ""}
          </span>
        </span>
        <span className="flex gap-2 items-center">
          <span className="font-b2 text-neutral-500 mr-2">
            {isFetchingWf
              ? "saving..."
              : updatedLast
              ? `last updated ${updatedLast} ago`
              : ""}
          </span>
          <Button
            variant="outline"
            disabled={isFetchingWf || globalIsFetching || isDownloadProgress}
            onClick={handleDownloadWf}
          >
            Download
          </Button>
          <Menu>
            <Menu.Button
              onClick={() => setIsChangeLog(false)}
              className="bg-primary-900 text-white font-medium !border-none w-fit !h-7 capitalize [&>img]:hidden"
            >
              <span className="font-b2 text-white mr-1">
                {workflow && STATUS_CONFIG_WITH_ARCHIVE[workflow?.status].label}
              </span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.23431 5.83432C4.54673 5.5219 5.05326 5.5219 5.36568 5.83432L7.99999 8.46863L10.6343 5.83432C10.9467 5.5219 11.4533 5.5219 11.7657 5.83432C12.0781 6.14674 12.0781 6.65327 11.7657 6.96569L8.56568 10.1657C8.25326 10.4781 7.74673 10.4781 7.43431 10.1657L4.23431 6.96569C3.9219 6.65327 3.9219 6.14674 4.23431 5.83432Z"
                  fill="white"
                />
              </svg>
            </Menu.Button>
            <Menu.Items>
              {Object.entries(STATUS_CONFIG_WITH_ARCHIVE).map(
                ([status, config]) =>
                  workflow?.status === status && (
                    <Fragment key={status}>
                      {config.allowedStatusList.map(
                        ({
                          status: allowedStatus,
                          disabled,
                          permissionsTag,
                        }) => (
                          <ProtectedComponent
                            type={PERMISSIONS_TYPE.policy as PermissionTypes}
                            action={permissionsTag}
                          >
                            <Menu.Item key={allowedStatus}>
                              <div
                                className={`flex items-center gap-2 px-3 py-2.5 ${
                                  disabled
                                    ? "opacity-50 cursor-not-allowed"
                                    : "cursor-pointer"
                                }`}
                                onClick={() => workflowStatusChange(disabled, allowedStatus)}
                              >
                                {disabled ? (
                                  <Tooltip
                                    placement="top-end"
                                    title=""
                                    toolTipContent={
                                      <>
                                        {titleCase(status)} workflow cannot be
                                        <br />
                                        moved to this state
                                      </>
                                    }
                                    className="z-20 mr-2"
                                  >
                                    <span
                                      className="relative border border-transparent cursor-not-allowed rounded-lg flex items-center"
                                      key={0}
                                    >
                                      <span className="flex gap-2">
                                        {
                                          STATUS_ICONS[
                                            allowedStatus as PolicyStatus
                                          ]
                                        }
                                        {
                                          STATUS_CONFIG_WITH_ARCHIVE[
                                            allowedStatus
                                          ].label
                                        }
                                      </span>
                                    </span>
                                  </Tooltip>
                                ) : (
                                  <>
                                    {
                                      STATUS_ICONS[
                                        allowedStatus as PolicyStatus
                                      ]
                                    }
                                    {
                                      STATUS_CONFIG_WITH_ARCHIVE[allowedStatus]
                                        .label
                                    }
                                  </>
                                )}
                              </div>
                            </Menu.Item>
                          </ProtectedComponent>
                        )
                      )}
                    </Fragment>
                  )
              )}
            </Menu.Items>
          </Menu>
        </span>
      </div>
      {confirmationType === "CONFIRM_ARCHIVE" && (
        <ConfirmationModal
          isOpen
          onClose={() => setConfirmationType("")}
          title="Are you sure?"
          action={() => onChangeStatus(PolicyStatus.ARCHIVE)}
          destructive
        >
          Warning! Archiving a policy is an irreversible action. You can view an
          archived policy but cant perform any update or edits on it.
        </ConfirmationModal>
      )}
      {confirmationType === "CONFIRM_READYTODEPLOY" && (
        <ConfirmationModal
          isOpen
          onClose={() => setConfirmationType("")}
          title="Are you sure?"
          action={() => onChangeStatus(PolicyStatus.READYTODEPLOY)}
        >
          You are about to make a policy live. Make sure the workflow is tested
          and ready to be deployed.
        </ConfirmationModal>
      )}
      {workflow && newStatus && (
        <StatusNotifyModal
          isNotifyModalOpen={true}
          oldStatus={workflow.status as PolicyStatus}
          newStatus={newStatus}
          onClose={() => {
            setNewStatus(undefined);
          }}
          action={onNotifyChangeStatus}
          entityType="workflow"
        />
      )}
    </>
  );
}
