import * as monacoEditor from "monaco-editor";
import { languages, Position } from "monaco-editor";

type FunctionInfoType = { name: string; label: string; description: string; syntax: string };
type FunctionListItem = Record<string, FunctionInfoType>;
type CompleteItemValue = string | FunctionInfoType;
type StringArr = string[];


interface BackEndKeywordsType {
  functionsList:FunctionListItem,
  predictorsList:Record<string, StringArr>,
  policies:Record<string, StringArr>,
  workflows:Record<string, StringArr>,
  input:StringArr
}

// Utility to generate range object
const getRange = (position: Position, currentWord: monacoEditor.editor.IWordAtPosition): monacoEditor.IRange => ({
  startColumn: currentWord.startColumn,
  endColumn: currentWord.endColumn,
  startLineNumber: position.lineNumber,
  endLineNumber: position.lineNumber,
});

// Utility to get syntax for suggestion item
const getSyntax = (value: CompleteItemValue) => 
  typeof value === 'object' && value?.syntax ? value.syntax : (typeof value === 'string' ? value : '');

// Utility to prepare Completion Item object
const makeSuggestionObj = (
  monaco: typeof monacoEditor,
  position: Position,
  currentWord: monacoEditor.editor.IWordAtPosition,
  value: CompleteItemValue
): languages.CompletionItem => ({
  range: getRange(position, currentWord),
  insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
  kind: monaco.languages.CompletionItemKind.Property || monaco.languages.CompletionItemKind.Function,
  documentation: typeof value === 'string' ? value : value.description,
  label: typeof value === 'string' ? value : { label: value.name, description: value.description },
  insertText: getSyntax(value),
});

// Function to handle different autocompletion strategies
const addCompletionItems = (
  monaco: typeof monacoEditor,
  position: Position,
  currentWord: monacoEditor.editor.IWordAtPosition,
  keywords: StringArr | Record<string, CompleteItemValue>,
  valueCompletionItems: languages.CompletionItem[]
) => {
  const suggestions = Array.isArray(keywords) 
    ? keywords.map((value) => makeSuggestionObj(monaco, position, currentWord, value))
    : Object.values(keywords ?? {}).map((value) => makeSuggestionObj(monaco, position, currentWord, value));

  valueCompletionItems.push(...suggestions);
};

// Function to get first-level suggestions
const getFirstLevelSuggestion = (
  monaco: typeof monacoEditor,
  position: Position,
  currentWord: monacoEditor.editor.IWordAtPosition,
  sourceList: string[],
  functionsList: FunctionListItem,
  lookupList: string[]
) => {
  const operators = ['and', 'or', '||', '&&'];
  let suggestions: Record<string, languages.CompletionItem> = {};

  operators.forEach(op => {
    suggestions[op] = {
      label: op,
      kind: monaco.languages.CompletionItemKind.Operator,
      documentation: op,
      insertText: op,
      range: getRange(position, currentWord),
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    };
  });

  [...sourceList, ...lookupList].forEach(item => {
    suggestions[item] = {
      label: item,
      kind: monaco.languages.CompletionItemKind.Property,
      documentation: item,
      insertText: item,
      range: getRange(position, currentWord),
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    };
  });

  Object.keys(functionsList ?? {}).forEach((funcKey) => {
    if(funcKey !=="LOOKUP"){
    const func = functionsList[funcKey];
    suggestions[funcKey] = {
      label: { label: funcKey, description: func.description },
      kind: monaco.languages.CompletionItemKind.Function,
      documentation: func.description,
      insertText: func.syntax,
      detail: func.syntax,
      range: getRange(position, currentWord),
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    };
    }
  });

  return suggestions;
};

// Prepare second-level suggestions
const prepareSecondLevelList = (
  predictorsList: Record<string, StringArr>,
  workflowsList: Record<string, StringArr>,
  policiesList: Record<string, StringArr>,
  input: StringArr
) => {
  const keywordObj: Record<string, StringArr> = { input, ...predictorsList };
  
  Object.keys(policiesList ?? {}).forEach(key => {
    keywordObj[`policies['${key}']`] = policiesList[key];
  });

  Object.keys(workflowsList ?? {}).forEach(key => {
    keywordObj[`workflows['${key}']`] = workflowsList[key];
  });

  return keywordObj;
};

// Main Autocomplete Function
export const workflowAutocomplete = (
  monaco: typeof monacoEditor,
  sourceList: string[],
  backEndKeywords: BackEndKeywordsType,
  autoCompleteRecords: Record<string, StringArr>,
  lookupFunctionInputs?: string[]
) => (
  model: monacoEditor.editor.ITextModel,
  position: Position
): languages.ProviderResult<languages.CompletionList> => {
  const currentWord = model.getWordUntilPosition(position);
  const prevWord = model.getWordUntilPosition(new monaco.Position(position.lineNumber, currentWord.startColumn - 1));

  const keywordRegex = ['policies', 'workflows']
    .map(key => new RegExp(`${key}\\['.*?'\\]`))
    .map(regex => model.findMatches(regex.source, { startColumn: 0, endColumn: currentWord.endColumn - 1, endLineNumber: position.lineNumber, startLineNumber: position.lineNumber }, true, false, null, true).at(-1))
    .filter(match => match?.matches && match.range.endColumn === currentWord.endColumn - 1)
    .map(match => match!.matches?.[0])
    .find(Boolean);

  const firstLevelSuggestion = getFirstLevelSuggestion(
    monaco,
    position,
    currentWord,
    sourceList,
    backEndKeywords.functionsList,
    lookupFunctionInputs ?? []
  );

  const currentSuggestion = prepareSecondLevelList(
    backEndKeywords.predictorsList ,
    backEndKeywords.workflows ,
    backEndKeywords.policies ,
    backEndKeywords.input
  );

  let valueCompletionItems: languages.CompletionItem[] = [];

  if (prevWord.word && currentSuggestion[prevWord.word]) {
    addCompletionItems(monaco, position, currentWord, currentSuggestion[prevWord.word] ?? [], valueCompletionItems);
    return { suggestions: valueCompletionItems };
  }

  if (keywordRegex && currentSuggestion[keywordRegex]) {
    addCompletionItems(monaco, position, currentWord, currentSuggestion[keywordRegex] ?? [], valueCompletionItems);
    return { suggestions: valueCompletionItems };
  }

  if (autoCompleteRecords[prevWord.word]) {
    addCompletionItems(monaco, position, currentWord, autoCompleteRecords[prevWord.word] ?? [], valueCompletionItems);
    return { suggestions: valueCompletionItems };
  }

  return { suggestions: currentWord.word.length ? Object.values(firstLevelSuggestion ?? {}) : [] };
};
